@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap);
*, ::before, ::after{
  box-sizing: border-box;
}
/* @font-face {
  font-family: "Borest";
  src: url("./fonts/Borest-Standard.otf")  format("opentype");
} */

body {
  margin: 0;
  font-family: 'Josefin Sans','-apple-system','BlinkMacSystemFont','Segoe UI','Roboto','Helvetica Neue','Arial','sans-serif','Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

a {
  text-decoration: none;
}

.slick-prev {
  left: 12px!important;
  z-index: 1!important;
}

.slick-next {
  z-index: 1!important;
  right: 12px!important;
}

.slick-prev:before, .slick-next:before {
  color: 'white';
}

.slick-initialized .slick-slide {
  display: block;
  width: inherit;
}

/* date range */
.react-datepicker__input-container input{
  width: 100%!important;
}

.slick-track {
  display: flex !important;
  align-items: center;
}

.slick-list {
  overflow: hidden !important;
}

/* .slick-list .slick-track{
  width: inherit!important;
} */

.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto; /* Ensure that all slides are centered properly */
}

.my-masonry-grid { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  /* background: grey; */
  margin-bottom: 30px;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .CalendarDay__selected_span {
    background: yellow;
    color: white;
} */

/* .CalendarDay__selected {
    background: red;
    color: white;
} */

/* .CalendarDay__selected:hover {
    background: orange;
    color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: brown;
} */

.DateInput_input {
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    padding: 0;
    border: none;
    background: none;
}

.DateRangePicker {
    width: 100%;
}

.DateInput {
    width: 70px;
    background: none;
}

.DateRangePickerInput {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
}

.DateRangePickerInput_arrow {
    margin: 0 10px;
}

