/* .CalendarDay__selected_span {
    background: yellow;
    color: white;
} */

/* .CalendarDay__selected {
    background: red;
    color: white;
} */

/* .CalendarDay__selected:hover {
    background: orange;
    color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: brown;
} */

.DateInput_input {
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    padding: 0;
    border: none;
    background: none;
}

.DateRangePicker {
    width: 100%;
}

.DateInput {
    width: 70px;
    background: none;
}

.DateRangePickerInput {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
}

.DateRangePickerInput_arrow {
    margin: 0 10px;
}
